import { useImportData } from "@use/useImportData";
import { reactive, ref, toRefs, watch } from "vue";

export const useImportDataOutput = (importType) => {
  const { importData, importMeta, isLoading, retrieveImportData, isInitialized } = useImportData({
    importType,
  });

  const options = ref({
    page: 1,
    itemsPerPage: 15,
    sortBy: [],
    sortDesc: [],
  });

  const filter = reactive({
    search: null,
    objectType: null,
    failedAtFrom: null,
    failedAtTo: null,
  });

  watch(options, async (value) => {
    if (!isInitialized.value) return;
    await retrieveImportData({
      ...value,
      perPage: value.itemsPerPage,
    });
  });

  watch(filter, async (value) => {
    if (!isInitialized.value) return;
    await retrieveImportData({
      ...value,
      perPage: value.itemsPerPage,
    });
  });

  return {
    importData,
    importMeta,
    isLoading,
    retrieveImportData,
    options,
    filter: toRefs(filter),
  };
};
