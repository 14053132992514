<template>
  <div>
    <BaseAlert type="error" class="ma-2" v-for="(message, index) in compiledErrors" :key="index">
      {{ message }}
    </BaseAlert>
  </div>
</template>

<script>
import { isArray } from "lodash";

export default {
  name: "ErrorMessages",

  props: {
    errorMessages: {
      type: Object,
      required: true,
      default: () => [],
    },
  },

  computed: {
    compiledErrors() {
      const errorList = [];
      Object.keys(this.errorMessages).forEach((key) => {
        if (isArray(this.errorMessages[key])) {
          this.errorMessages[key].forEach((message) => {
            errorList.push(message);
          });
        } else {
          Object.keys(this.errorMessages[key]).forEach((subKey) => {
            this.errorMessages[key][subKey].forEach((subMessage) => {
              errorList.push(subMessage);
            });
          });
        }
      });

      return errorList;
    },
  },
};
</script>
