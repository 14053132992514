<template>
  <v-data-table
    :items="imports"
    v-bind="$attrs"
    v-on="$listeners"
    single-expand
    show-expand
    item-key="id"
    :headers="tableHeader"
    :footer-props="footerProps"
  >
    <template #[`item.id`]="{ item }">
      <span @click="showDetailPage(item.id)" class="clickable">
        {{ item.id }}
      </span>
    </template>

    <template #[`item.failedAt`]="{ item }">
      {{ dateTime(item.failedAt) }}
    </template>

    <template v-slot:expanded-item="{ headers, item }">
      <td :colspan="headers.length">
        <ErrorMessages :errorMessages="item.errors" />
      </td>
    </template>
  </v-data-table>
</template>

<script>
import dateFilter from "@mixins/dateFilter";
import ErrorMessages from "@views/Restricted/Navision/Imports/List/partials/ErrorMessages.vue";

export default {
  name: "ImportTable",

  mixins: [dateFilter],

  components: {
    ErrorMessages,
  },

  props: {
    imports: {
      type: Array,
      required: true,
      default: () => [],
    },

    headers: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      footerProps: {
        "items-per-page-options": [15, 25, 50],
      },
    };
  },

  methods: {
    async showDetailPage(importId) {
      try {
        await this.$router.push({ name: "ImportsShow", params: { importId } });
      } catch {
        return;
      }
    },
  },

  computed: {
    tableHeader() {
      return [
        {
          text: this.$t("NAVISION.IMPORTS.LIST.TABLE.HEADER_GENERAL.IDENTIFICATION"),
          value: "id",
          sortable: true,
        },

        ...this.headers,

        {
          text: this.$t("NAVISION.IMPORTS.LIST.TABLE.HEADER_GENERAL.FAILED_AT"),
          value: "failedAt",
          sortable: true,
        },
      ];
    },

    routerPath() {
      return this.$route.path;
    },
  },
};
</script>
