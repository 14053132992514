import { onMounted, ref } from "vue";

export const useImportData = ({ importType = "seminar" } = {}) => {
  const isLoading = ref(false);
  const importData = ref([]);
  const importMeta = ref({});

  const isInitialized = ref(false);

  const retrieveImportData = async ({
    perPage = 15,
    page = 1,
    search = null,
    objectType = null,
    failedAtFrom = null,
    failedAtTo = null,
    sortBy = [],
    sortDesc = [],
  } = {}) => {
    isLoading.value = true;

    try {
      const { data } = await window.$axios.navision.listImports({
        importType,
        perPage,
        page,
        search,
        objectType,
        failedAtFrom,
        failedAtTo,
        sortBy,
        sortDesc,
      });

      importData.value = data.data;
      importMeta.value = data.meta;

      isInitialized.value = true;
    } finally {
      isLoading.value = false;
    }
  };

  onMounted(async () => {
    await retrieveImportData();
  });

  return { importData, importMeta, isLoading, retrieveImportData, isInitialized };
};
